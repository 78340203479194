<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/case_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">经典案例</p>
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h95"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabs"> </Tab>
        <div class="main_title_left">传世耗材柜深受客户好评</div>
        <img class="w100f" src="../../assets/images/case_cabinet1.png" alt="" />
        <div class="main_title_left">客户简介</div>
        <p class="main-content-txt mb50">
          上海中医药大学附属曙光医院是沪上一所百年老院，三级甲等综合性中医院，省部共建研究型中医院，位列上海十大综合性医院之一，全国示范中医院，全国文明单位。1984—2018年十八次蝉联“上海市文明单位”称号（上海市卫生系统唯一），多次获得全国卫生系统先进集体荣誉和全国医院文化建设先进单位称号。2001年成为全国首家通过ISO9001质量管理体系认证的中医医院。2010年以来，医院7次获得上海申康医院发展中心市级医院院长绩效考核A级。
        </p>
        <div class="content-img-box">
          <img class="content-img-left" src="../../assets/images/case_cabinet2.png" alt="" />
          <img class="content-img-right" src="../../assets/images/case_cabinet3.png" alt="" />
        </div>
        <div class="main_title_left">项目介绍简介</div>
        <p class="main-content-txt">2019年6月，传世智能耗材柜一批（三主十辅）进场；</p>
        <p class="main-content-txt">共管理二级库高值耗材1000件以上，日均使用量200件左右。</p>
        <p class="main-content-txt">联调通过后，用于新院区手术室及麻醉科，目前产品已完成部署上线，使用情况良好。</p>
        <div class="h120"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import mobilePage from '@/views/case/mobile/case_cabinet.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Tab,
    Title,
    mobilePage,
  },
  data() {
    return {
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.tabs.currentIndex = 2;
    });
  },
  methods: {
    onTabChange(item, index) {},
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 42px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .font-title {
      font-size: 32px;
      text-align: left;
      margin-top: 53px;
      margin-bottom: 24px;
    }
    .case-img {
      width: 70%;
      margin-top: 60px;
      margin-bottom: 117px;
    }
    .main-content-txt {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 10px;
      text-indent: 2em;
    }
    .content-img-box {
      display: flex;
      justify-content: space-between;
      .content-img-left {
        width: 63%;
      }
      .content-img-right {
        width: 36%;
      }
    }
  }
}
</style>
