<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_case_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange"  ref="tabs" ></mobileTab>
    <div class="mobile-case-box">
      <div class="case-title">传世耗材柜深受客户好评</div>
      <img class="w100f" :src="gzhcImg1" alt="">
      <h2>客户简介</h2>
      <p>上海中医药大学附属曙光医院是沪上一所百年老院，三级甲等综合性中医院，省部共建研究型中医院，位列上海十大综合性医院之一，全国示范中医院，全国文明单位。1984—2018年十八次蝉联“上海市文明单位”称号（上海市卫生系统唯一），多次获得全国卫生系统先进集体荣誉和全国医院文化建设先进单位称号。2001年成为全国首家通过ISO9001质量管理体系认证的中医医院。2010年以来，医院7次获得上海申康医院发展中心市级医院院长绩效考核A级。</p>
      <img class="w100f mt_026" :src="gzhcImg2" alt="">
      <img class="w100f mt_026" :src="gzhcImg3" alt="">
      <h2>项目介绍</h2>
      <p >2019年6月，传世智能耗材柜一批（三主十辅）进场；</p>
      <p >共管理二级库高值耗材1000件以上，日均使用量200件左右。</p>
      <p >联调通过后，用于新院区手术室及麻醉科，目前产品已完成部署上线，使用情况良好。</p>
    </div>
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      gzhcImg1:require('../../../assets/mobile_images/mo_case_gzhc1.png'),
      gzhcImg2:require('../../../assets/mobile_images/mo_case_gzhc2.png'),
      gzhcImg3:require('../../../assets/mobile_images/mo_case_gzhc3.png'),
      currentIndex: 0,
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs.tabs.currentIndex = 2
    })
  },
  methods: {
    onTabChange(item, index) {
    //   this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-case-box{
    color: #3D3D3D;
    padding: 0 .4rem;
    box-sizing: border-box;
    .case-title{
      font-size: .4rem;
      text-align: left;
      padding: .1rem 0 .28rem 0;
    }
    h2{
      font-size: .32rem;
      text-align: left;
      margin: .46rem 0 .42rem 0;
    }
    p{
      font-size: .28rem;
      line-height: .5rem;
      text-align: left;
      text-indent: 2em;
      margin-bottom: .2rem;
    }
    .mt_026{
      margin-top: .26rem;
    }
  }
}
</style>
